<template>
  <div class="container" id="contact-us">
    <div class="title">
      <h2>Contact Us</h2>
    </div>
    <div class="text">
      <span class="f">Fields marked with <span>*</span> are required.</span>
    </div>
    <form @submit.prevent="submitForm">
      <div class="questions">
        <div class="left">
          <div class="question">
            <label for="name"><span>*</span> Name:</label>
            <input type="text" v-model="formData.name" id="name" required />
          </div>
          <div class="question">
            <label for="company">Company Name:</label>
            <input type="text" v-model="formData.company" id="company" />
          </div>
          <div class="question">
            <label for="howHelpYou"><span>*</span> How can we help you?:</label>
            <select v-model="formData.howHelpYou" id="howHelpYou" required>
              <option disabled value="">Please select one</option>
              <option value="SEO">SEO</option>
              <option value="Digital Marketing">Digital Marketing</option>
              <option value="Web Design">Web Design</option>
              <option value="Logo Design">Logo Design</option>
            </select>
          </div>
          <div class="question">
            <label for="projectInMind"
              >Do you have a specific project in mind?:</label
            >
            <textarea
              type="text"
              v-model="formData.projectInMind"
              id="projectInMind"
              rows="4"
            ></textarea>
          </div>
        </div>
        <div class="right">
          <div class="question">
            <label for="estimatedBudget"
              ><span>*</span> What is your estimated budget for this
              project?:</label
            >
            <select
              v-model="formData.estimatedBudget"
              id="estimatedBudget"
              required
            >
              <option disabled value="">Please select your budget range</option>
              <option value="under-1000">$500 - $1,000</option>
              <option value="1001-5000">$1,001 - $5,000</option>
              <option value="5001,10000">$5,001 - $10,000</option>
              <option value="10001-20000">$10,001 - $20,000</option>
              <option value="above-20001">$20,001+</option>
            </select>
          </div>
          <div class="question">
            <label for="idealTimeline"
              ><span>*</span> What is your ideal timeline?:</label
            >
            <select
              v-model="formData.idealTimeline"
              id="idealTimeline"
              required
            >
              <option disabled value="">
                Please select your ideal timeline
              </option>
              <option value="2 week">2 week</option>
              <option value="3 week">3 week</option>
              <option value="1 month">1 month</option>
              <option value="2 month">2 month</option>
            </select>
          </div>
          <div class="question">
            <label for="email"><span>*</span> Email Address:</label>
            <input type="email" v-model="formData.email" id="email" required />
          </div>
          <div class="question">
            <label for="tel"><span>*</span> Phone Number:</label>
            <input type="tel" v-model="formData.tel" id="tel" required />
          </div>
          <div class="question">
            <label for="files"
              >Do you have any references or inspiration you'd like to
              share?:</label
            >
            <input
              type="file"
              @change="handleFileChange"
              name="file"
              id="files"
              multiple
            />
          </div>
        </div>
      </div>
      <div class="button">
        <button type="submit"><span>SEND</span></button>
        <p v-if="status === 'sending'" class="yellow">Sending...</p>
        <p v-if="status === 'success'" class="green">Form sent successfully!</p>
        <p v-if="status === 'error'" class="red">
          There was an error sending the form.
        </p>
      </div>
    </form>
  </div>
</template>

<script>
/* eslint-disable */

export default {
    name: "Contact",
    data() {
        return {
            formData: {
                name: '',
                email: '',
                tel: '',
                company: '',
                howHelpYou: '',
                projectInMind: '',
                estimatedBudget: '',
                idealTimeline: '',
            },
            files: [], //An array to store the selected files
            status: null, // For form status feedback
        };
    },
    methods: {
        handleFileChange(event) {
            this.files = Array.from(event.target.files); // Store multiplefiles as an array
        },
        async submitForm() {
            this.status = 'sending';
            const formData = new FormData();
            formData.append('Name', this.formData.name);
            formData.append('Email', this.formData.email);
            formData.append('Phone Number', this.formData.tel);
            formData.append('Company Name', this.formData.company);
            formData.append('How can we help you', this.formData.howHelpYou);
            formData.append('Do you have a specific project in mind', this.formData.projectInMind);
            formData.append('What is your estimated budget for this project', this.formData.estimatedBudget);
            formData.append('What is your ideal timeline', this.formData.idealTimeline);

            // Append all files to the FormData
            this.files.forEach((file, index) => {
                formData.append(`Files[${index}]`, file);
            });

            try {
                const response = await fetch('https://formspree.io/f/mkgnnqpa', {
                    method: 'POST',
                    body: formData,
                    headers: {
                        'Accept': 'application/json',
                    },
                });

                if (response.ok) {
                    this.status = 'success';
                    this.formData = {
                        name: '',
                        email: '',
                        tel: '',
                        company: '',
                        howHelpYou: '',
                        projectInMind: '',
                        estimatedBudget: '',
                        idealTimeline: ''
                    };
                    this.files = [];
                } else {
                    this.status = 'error';
                }
            } catch (error) {
                console.error(error);
                this.status = 'error';
            }
        },
    }
}
</script>

<style lang="scss" scoped>
$background-color: #000000;
$primary-color: #ffffff;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-24: 24px;
$font-size-36: 36px;
$font-size-48: 48px;
$icon-size: 24px;
$color-red: #ff2a0c;
$color-green: #53c216;
$color-blue: #0084ff;
$color-yellow: #efe12a;
$time: 4s;
$shadow-size: 5px;
$negative-shadow: -5px;

@keyframes shadow {
    0% {
        border: 1px solid $color-yellow;
        box-shadow: $shadow-size $shadow-size 10px $color-yellow;
    }
    25% {
        border: 1px solid $color-blue;
        box-shadow: $negative-shadow $shadow-size 10px $color-blue;
    }
    50% {
        border: 1px solid $color-red;
        box-shadow: $negative-shadow $negative-shadow 10px $color-red;
    }
    75% {
        border: 1px solid $color-green;
        box-shadow: $shadow-size $negative-shadow 10px $color-green;
    }
    100% {
        border: 1px solid $color-yellow;
        box-shadow: $shadow-size $shadow-size 10px $color-yellow;
    }
}

@media (min-width: 769px) {
    .container {
        background-color: $background-color;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px;

            h2 {
                color: $primary-color;
                font-size: $font-size-24;
            }
        }

        .text {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 750px;
            height: auto;

            .f {
                font-size: $font-size-12;
                color: $primary-color;

                span {
                    font-size: $font-size-14;
                    color: $color-yellow;
                }
            }
        }

        form {
            width: 750px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: auto;
            border-radius: 15px;
            box-shadow: $negative-shadow $negative-shadow $color-red;
            border: 1px solid $color-red;
            margin-top: 10px;
            padding: 15px;

            .questions {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                width: 100%;
                height: auto;
                margin-top: 20px;

                .question {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    margin-bottom: 15px;

                    label {
                        font-size: $font-size-16;
                        color: $primary-color;
                        margin-right: 10px;
                        font-weight: 700;

                        span {
                            color: $color-yellow;
                            font-size: $font-size-18;
                        }
                    }

                    input, select, textarea {
                        padding:  10px 15px 10px 15px;
                        border-radius: 25px;
                        border: 1px solid $color-green;
                        color: $primary-color;
                        font-size: $font-size-14;
                        background-color: $background-color;
                    }
                }
            }

            .button {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: auto;

                button {
                    margin: 20px;
                    animation: shadow $time infinite;
                    cursor: pointer;
                    border-radius: 25px;
                    padding: 15px;
                    background: $background-color;
                    width: 150px;
                    height: auto;

                    span {
                        color: $primary-color;
                        font-size: $font-size-14;
                        font-weight: 700;
                    }
                }

                p {
                    font-size: $font-size-14;
                    margin: 20px;
                }

                p.red {
                    color: $color-red;
                }
                p.yellow {
                     color: $color-yellow;
                }
                p.green {
                    color: $color-green;
                }
            }
        }
    }
}
@media (max-width: 768px){
    .container {
        background-color: $background-color;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
            width: 90%;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px;

            h2 {
                color: $primary-color;
                font-size: $font-size-24;
            }
        }

        .text {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 90%;
            height: auto;

            .f {
                font-size: $font-size-12;
                color: $primary-color;

                span {
                    font-size: $font-size-14;
                    color: $color-yellow;
                }
            }
        }

        form {
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: auto;
            border-radius: 15px;
            box-shadow: $negative-shadow $negative-shadow $color-red;
            border: 1px solid $color-red;
            margin-top: 10px;
            padding: 15px;

            .questions {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 90%;
                height: auto;
                margin-top: 20px;

                .question {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    flex-wrap: wrap;
                    margin: 15px;
                    width: 100%;

                    label {
                        font-size: $font-size-16;
                        color: $primary-color;
                        font-weight: 700;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        span {
                            color: $color-yellow;
                            font-size: $font-size-18;
                        }
                    }

                    input, select, textarea {
                        padding:  10px 15px 10px 15px;
                        border-radius: 25px;
                        border: 1px solid $color-green;
                        color: $primary-color;
                        font-size: $font-size-14;
                        background-color: $background-color;
                        margin-top: 10px;
                        max-width: 250px;
                    }
                }
            }

            .button {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: auto;

                button {
                    margin: 20px;
                    animation: shadow $time infinite;
                    cursor: pointer;
                    border-radius: 25px;
                    padding: 15px;
                    background: $background-color;
                    width: 150px;
                    height: auto;

                    span {
                        color: $primary-color;
                        font-size: $font-size-14;
                        font-weight: 700;
                    }
                }

                p {
                    font-size: $font-size-14;
                    margin: 20px;
                }

                p.red {
                    color: $color-red;
                }
                p.yellow {
                     color: $color-yellow;
                }
                p.green {
                    color: $color-green;
                }
            }
        }
    }
}
</style>