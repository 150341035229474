<template>
  <div class="FAQs" id="FAQs">
    <h2>FAQs</h2>
    <div v-if="questions.length" class="questions">
      <Question
        v-for="(q, index) in questions"
        :key="index"
        :question="q"
      ></Question>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Question from './Question.vue';

export default {
    name: "FAQs",
    components: {
        Question,
    },
    data() {
    return {
      questions: [],
    };
  },
  mounted() {
    fetch("/json/questions.json")
      .then((response) => response.json())
      .then((data) => {
        this.questions = data;
      })
      .catch((error) => {
        console.error("Error fetching JSON:", error);
      });
  },
}
</script>

<style lang="scss" scoped>
$background-color: #000000;
$primary-color: #ffffff;
$font-size: 18px;
$color-red: #ff2a0c;
$color-green: #53c216;
$color-blue: #0084ff;
$color-yellow: #efe12a;

@media (min-width: 769px){
    .FAQs {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        background-color: $background-color;
        padding: 50px 0 50px 0;
        
        h2 {
            color: $primary-color;
            margin: 50px;
        }

        .questions {
            display: flex;
            flex-direction: column;
            width: 650px;
            height: auto;
            justify-content: center;
            align-items: center;
            gap: 20px;
        }
    }
}
@media (max-width: 768px){
    .FAQs {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        background-color: $background-color;
        padding: 50px 0 50px 0;
        
        h2 {
            color: $primary-color;
            margin: 50px;
        }

        .questions {
            display: flex;
            flex-direction: column;
            width: 90%;
            height: auto;
            justify-content: center;
            align-items: center;
            gap: 20px;
        }
    }
}
</style>