<template>
  <div class="who" id="about">
    <div class="body">
      <h2>Who We Are: The Creative Minds Behind the Magic!</h2>
      <p>
        At <span class="red">Creative Pixel Studio</span>, we’re not just
        designers—we’re digital magicians! With a passion for creativity and a
        love for all things tech,
        <span class="green">we transform ideas</span> into visually stunning
        <span class="blue">websites and logos</span> that captivate your
        audience. Whether you’re looking for a sleek web design or a bold new
        logo, we’ve got the skills (and the coffee) to bring your vision to
        life.<span class="yellow">Let’s create something unforgettable</span>,
        one pixel at a time!
      </p>
      <div class="button" @click.prevent="scrollToContact">
        <span>Ready to Meet Your Creative Partners?</span>
        <span class="red">Let's Make Magic Together!</span>
      </div>
    </div>
    <div class="logo">
      <img
        src="../assets/Logo_on_construction.svg"
        alt="Logo on construction"
        width="500px"
        height="500px"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
    name: "WhoWeAre",
    methods: {
      scrollToContact() {
        const element = document.getElementById("contact-us");
        if (element) {
          element.scrollIntoView({behavior: "smooth"});
        }
      },
    },
}
</script>

<style lang="scss" scoped>
$img-size: 500px;
$background-color: #000000;
$primary-color: #ffffff;
$font-size: 18px;
$color-red: #ff2a0c;
$color-green: #53c216;
$color-blue: #0084ff;
$color-yellow: #efe12a;
$shadow-size: 5px;
$negative-shadow: -5px;
$time: 4s;
$weight700: 700;

@keyframes shadow {
    0% {
        border: 1px solid $color-yellow;
        box-shadow: $shadow-size $shadow-size 10px $color-yellow;
    }
    25% {
        border: 1px solid $color-blue;
        box-shadow: $negative-shadow $shadow-size 10px $color-blue;
    }
    50% {
        border: 1px solid $color-red;
        box-shadow: $negative-shadow $negative-shadow 10px $color-red;
    }
    75% {
        border: 1px solid $color-green;
        box-shadow: $shadow-size $negative-shadow 10px $color-green;
    }
    100% {
        border: 1px solid $color-yellow;
        box-shadow: $shadow-size $shadow-size 10px $color-yellow;
    }
}

@keyframes color {
    0% {
        color: $color-yellow;
    }
    25% {
        color: $color-blue;
    }
    50% {
        color: $color-red;
    }
    75% {
        color: $color-green;
    }
    100% {
        color: $color-yellow;
    }
}

@media (min-width: 1000px){
    .who {
        width: 100%;
        height: $img-size;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        background-color: $background-color;

        .body {
            width: calc(100% - $img-size);
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h2 {
                color: $primary-color;
            }

            p {
                color: $primary-color;
                font-size: $font-size;
                margin: 30px;

                span {
                    font-weight: $weight700;
                    font-size: calc($font-size + 2px);
                }
                span.red {
                    color: $color-red;
                }
                span.green {
                    color: $color-green;
                }
                span.blue {
                    color: $color-blue;
                }
                span.yellow {
                    color: $color-yellow;
                }
            }

            .button {
                border: 1px solid $color-red;
                border-radius: 25px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                box-shadow: -5px -5px $color-red;
                animation: shadow $time infinite;

                span {
                    margin: 20px 20px 0 20px;
                    color: $primary-color;
                    font-size: $font-size;
                }

                span.red {
                    margin-bottom: 20px;
                    color: $color-red;
                    font-weight: 700;
                    transition: color ease 0.5s;
                }
            }

            .button:hover {
                span.red {
                    color: $color-green;
                }
            }
        }

        .logo {
            width: $img-size;
            height: $img-size;
        }
    }
}
@media (max-width: 999px){
    .who {
        background-color: $background-color;
        display: flex;
        width: 100%;
        height: auto;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .body {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-left: 50px;
            padding-top: 50px;
            padding-bottom: 50px;

            h2 {
                color: $primary-color;
            }

            p {
                color: $primary-color;
                margin-top: 50px;

                span {
                    font-weight: $weight700;
                    font-size: calc($font-size + 2px);
                }
                span.red {
                    color: $color-red;
                }
                span.green {
                    color: $color-green;
                }
                span.blue {
                    color: $color-blue;
                }
                span.yellow {
                    color: $color-yellow;
                }
            }

            .button {
                border: 1px solid $color-red;
                border-radius: 25px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                box-shadow: -5px -5px $color-red;
                animation: shadow $time infinite;
                margin-top: 25px;

                span {
                    margin: 20px 20px 0 20px;
                    color: $primary-color;
                    font-size: $font-size;
                }

                span.red {
                    margin-bottom: 20px;
                    color: $color-red;
                    font-weight: 700;
                    transition: color ease 0.5s;
                }
            }

            .button:hover {
                span.red {
                    color: $color-green;
                }
            }
        }

        .logo {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            height: auto;

            img {
                width: 300px;
                height: auto;
            }
        }
    }
}
@media (max-width: 768px){
    .who {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: auto;
        background-color: $background-color;

        .body {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-right: 30px;

            h2 {
                width: 100%;
                height: auto;
                color: $primary-color;
            }

            p {
                width: 100%;
                height: auto;
                margin-top: 20px;
                color: $primary-color;
            }

            .button {
                width: auto;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                animation: shadow 4s infinite;
                border-radius: 25px;
                margin-top: 20px;

                span {
                    margin: 10px 10px 0 10px;
                    color: $primary-color;
                    font-size: $font-size;
                }

                span.red {
                    animation: color $time infinite;
                    font-weight: 700;
                    margin-bottom: 10px;
                }
            }
        }

        .logo {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}
</style>