<template>
  <div class="container" id="process">
    <div class="title">
      <h2>Our Creative Process: Turning Ideas Into Digital Masterpieces</h2>
    </div>
    <div class="steps">
      <div class="firstSteps">
        <div class="contact">
          <div class="icons">
            <i class="fa-solid fa-turn-up"></i>
          </div>
          <div class="box">
            <span>Contact Us</span>
          </div>
        </div>
        <div class="design">
          <div class="box">
            <span>Design Phase</span>
          </div>
          <div class="icons">
            <i class="fa-solid fa-turn-up"></i>
            <i class="fa-solid fa-arrow-down"></i>
          </div>
        </div>
      </div>
      <div class="secondSteps">
        <div class="execution">
          <div class="icons">
            <i class="fa-solid fa-turn-up"></i>
          </div>
          <div class="box">
            <span>Execution Phase</span>
          </div>
        </div>
        <div class="maintenance">
          <div class="box">
            <span>Maintenance & Support</span>
          </div>
          <div class="icons">
            <i class="fa-solid fa-turn-up"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "Process",
};
</script>

<style lang="scss" scoped>
$background-color: #000000;
$primary-color: #ffffff;
$font-size: 18px;
$color-red: #ff2a0c;
$color-green: #53c216;
$color-blue: #0084ff;
$color-yellow: #efe12a;

@media (min-width: 769px){
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        padding-top: 50px;
        padding-bottom: 50px;
        background-color: $background-color;

        .title {
            color: $primary-color;
        }

        .steps {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: auto;
            gap: 20px;
            margin-top: 50px;

            .firstSteps {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                width: 50%;
                height: auto;
                gap: 20px;

                .contact {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-around;
                    width: 200px;
                    height: 300px;

                    .icons {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        i {
                            font-size: 100px;
                            color: $color-red;
                            transform: rotateY(0deg) rotateX(180deg) rotateZ(90deg);
                        }
                    }

                    .box {
                        width: 150px;
                        height: 150px;
                        border-radius: 15px;
                        border: 1px solid $color-red;
                        box-shadow: -5px -5px $color-red;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        span {
                            color: $primary-color;
                            font-size: $font-size;
                            font-weight: 700;
                        }
                    }
                }

                .design {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-around;
                    width: 200px;
                    height: 300px;

                    .icons {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        i {
                            font-size: 100px;
                            color: $color-green;
                            transform: rotateY(180deg) rotateX(180deg) rotateZ(270deg);
                        }
                        i.fa-arrow-down {
                            display: none;
                        }
                    }

                    .box {
                        width: 150px;
                        height: 150px;
                        border-radius: 15px;
                        border: 1px solid $color-green;
                        box-shadow: -5px -5px $color-green;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        span {
                            color: $primary-color;
                            font-size: $font-size;
                            font-weight: 700;
                        }
                    }
                }
            }

            .secondSteps {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                width: 50%;
                height: auto;
                gap: 20px;

                .execution {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-around;
                    width: 200px;
                    height: 300px;

                    .icons {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        i {
                            font-size: 100px;
                            color: $color-blue;
                            transform: rotateY(0deg) rotateX(180deg) rotateZ(90deg);
                        }
                    }

                    .box {
                        width: 150px;
                        height: 150px;
                        border-radius: 15px;
                        border: 1px solid $color-blue;
                        box-shadow: -5px -5px $color-blue;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        span {
                            color: $primary-color;
                            font-size: $font-size;
                            font-weight: 700;
                        }
                    }
                }

                .maintenance {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-around;
                    width: 200px;
                    height: 300px;

                    .box {
                        width: 150px;
                        height: 150px;
                        border-radius: 15px;
                        border: 1px solid $color-yellow;
                        box-shadow: -5px -5px $color-yellow;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        span {
                            color: $primary-color;
                            font-size: $font-size;
                            font-weight: 700;
                        }
                    }

                    .icons {
                        display: hidden;
                        justify-content: center;
                        align-items: center;

                        i {
                            font-size: 100px;
                            color: $color-red;
                            transform: rotateY(90deg) rotateX(180deg) rotateZ(90deg);
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 768px){
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        padding-top: 50px;
        padding-bottom: 50px;
        background-color: $background-color;

        .title {
            color: $primary-color;
        }

        .steps {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: auto;
            gap: 20px;
            margin-top: 50px;

            .firstSteps {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 50%;
                height: auto;
                gap: 20px;

                .contact {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-around;
                    width: 200px;
                    height: 300px;

                    .icons {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        i {
                            font-size: 100px;
                            color: $color-red;
                            transform: rotateY(0deg) rotateX(180deg) rotateZ(90deg);
                        }
                    }

                    .box {
                        width: 150px;
                        height: 150px;
                        border-radius: 15px;
                        border: 1px solid $color-red;
                        box-shadow: -5px -5px $color-red;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        span {
                            color: $primary-color;
                            font-size: $font-size;
                            font-weight: 700;
                        }
                    }
                }

                .design {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-around;
                    width: 200px;
                    height: 300px;

                    .icons {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        i {
                            font-size: 100px;
                            color: $color-green;
                            transform: rotateY(180deg) rotateX(180deg) rotateZ(180deg);
                        }
                        i.fa-turn-up {
                            display: none;
                        }
                    }

                    .box {
                        width: 150px;
                        height: 150px;
                        border-radius: 15px;
                        border: 1px solid $color-green;
                        box-shadow: -5px -5px $color-green;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        span {
                            color: $primary-color;
                            font-size: $font-size;
                            font-weight: 700;
                        }
                    }
                }
            }

            .secondSteps {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                justify-content: center;
                width: 50%;
                height: auto;
                gap: 20px;

                .execution {
                    display: flex;
                    flex-direction: column-reverse;
                    align-items: center;
                    justify-content: space-around;
                    width: 200px;
                    height: 300px;

                    .icons {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        i {
                            font-size: 100px;
                            color: $color-blue;
                            transform: rotateY(180deg) rotateX(0deg) rotateZ(90deg);
                        }
                    }

                    .box {
                        width: 150px;
                        height: 150px;
                        border-radius: 15px;
                        border: 1px solid $color-blue;
                        box-shadow: -5px -5px $color-blue;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        span {
                            color: $primary-color;
                            font-size: $font-size;
                            font-weight: 700;
                        }
                    }
                }

                .maintenance {
                    display: flex;
                    flex-direction: column-reverse;
                    align-items: center;
                    justify-content: space-around;
                    width: 200px;
                    height: 300px;

                    .box {
                        width: 150px;
                        height: 150px;
                        border-radius: 15px;
                        border: 1px solid $color-yellow;
                        box-shadow: -5px -5px $color-yellow;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        span {
                            color: $primary-color;
                            font-size: $font-size;
                            font-weight: 700;
                        }
                    }

                    .icons {
                        display: hidden;
                        justify-content: center;
                        align-items: center;

                        i {
                            font-size: 100px;
                            color: $color-red;
                            transform: rotateY(90deg) rotateX(180deg) rotateZ(90deg);
                        }
                    }
                }
            }
        }
    }
}
</style>
