<template>
  <div :class="[question.color, { visible: isToggle }]" class="container">
    <div class="q">
      <h3>{{ question.question }}</h3>
      <div class="icons">
        <i @click="toggle" v-if="!isToggle" class="fa-solid fa-plus"></i>
        <i @click="toggle" v-else class="fa-solid fa-minus"></i>
      </div>
    </div>
    <div v-if="isToggle" class="a">
      <span>{{ question.answer }}</span>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
    name: "Question",
    data() {
        return {
            isToggle: false,
        };
    },
    props: {
        question: {
            type: Object,
            required: true,
        },
    },
    methods: {
        toggle() {
            this.isToggle = !this.isToggle;
        },
    },
}
</script>

<style lang="scss" scoped>
$background-color: #000000;
$primary-color: #ffffff;
$font-size: 18px;
$font-size-14: 14px;
$icon-size: 24px;
$color-red: #ff2a0c;
$color-green: #53c216;
$color-blue: #0084ff;
$color-yellow: #efe12a;
@media (min-width: 769px){
    .container {
        width: 100%;
        height: 50px;
        transition: height ease 1s;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        overflow: hidden;
        border-radius: 25px;

        .q {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 50px;
            justify-content: center;
            align-items: center;

            h3 {
                width: calc(100% - 50px);
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $primary-color;
            }

            .icons {
                width: 50px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                i {
                    color: $primary-color;
                    font-size: $icon-size;
                    cursor: pointer;
                }
            }
        }

        .a {
            display: flex;
            width: 100%;
            height: 100px;
            justify-content: center;
            align-items: center;
            padding: 0 30px 0 30px;
            
            span {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $primary-color;
            }
        }
    }

    .container.visible {
        height: 150px;
    }

    .container.red {
        border: 1px solid $color-red;
        box-shadow: -5px -5px $color-red;
    }
    .container.green {
        border: 1px solid $color-green;
        box-shadow: -5px -5px $color-green;
    }
    .container.blue {
        border: 1px solid $color-blue;
        box-shadow: -5px -5px $color-blue;
    }
    .container.yellow {
        border: 1px solid $color-yellow;
        box-shadow: -5px -5px $color-yellow;
    }
}
@media (max-width: 768px){
    .container {
        width: 100%;
        height: 50px;
        transition: height ease 1s;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        overflow: hidden;
        border-radius: 25px;

        .q {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 50px;
            justify-content: center;
            align-items: center;

            h3 {
                width: calc(100% - 50px);
                height: 100%;
                display: flex;
                font-size: $font-size-14;
                align-items: center;
                justify-content: center;
                color: $primary-color;
                margin: 0 15px 0 15px;
            }

            .icons {
                width: 50px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                i {
                    color: $primary-color;
                    font-size: $icon-size;
                    cursor: pointer;
                }
            }
        }

        .a {
            display: flex;
            width: 100%;
            height: 100px;
            justify-content: center;
            align-items: center;
            padding: 0 15px 0 15px;
            
            span {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $primary-color;
                font-size: $font-size-14;
            }
        }
    }

    .container.visible {
        height: 150px;
    }

    .container.red {
        border: 1px solid $color-red;
        box-shadow: -5px -5px $color-red;
    }
    .container.green {
        border: 1px solid $color-green;
        box-shadow: -5px -5px $color-green;
    }
    .container.blue {
        border: 1px solid $color-blue;
        box-shadow: -5px -5px $color-blue;
    }
    .container.yellow {
        border: 1px solid $color-yellow;
        box-shadow: -5px -5px $color-yellow;
    }
}

</style>