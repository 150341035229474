<template>
  <div class="container" id="home">
    <div class="imgs">
      <picture>
        <source srcset="../assets/webp/background.webp" type="image/webp" />
        <img
          src="../assets/png/background.png"
          alt="background of tecnology"
          width="500px"
          height="500px"
        />
      </picture>
      <div class="blur"></div>
    </div>
    <div class="title">
      <h2>Transforming Ideas Into Impactful Digital Experiences</h2>
      <div class="button" @click.prevent="scrollToContact">
        <p>
          Bring Your Vision to Life - <span class="red">Get Started Now!</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
    name: "TitleCTA",
    methods: {
      scrollToContact() {
        const element = document.getElementById("contact-us");
        if (element) {
          element.scrollIntoView({behavior: "smooth"});
        }
      },
    },
}
</script>

<style lang="scss" scoped>
$background-color: #000000;
$primary-color: #ffffff;
$font-size: 18px;
$font-size-14: 14px;
$font-size-24: 24px;
$font-size-36: 36px;
$font-size-48: 48px;
$icon-size: 24px;
$color-red: #ff2a0c;
$color-green: #53c216;
$color-blue: #0084ff;
$color-yellow: #efe12a;
$time: 4s;
$shadow-size: 5px;
$negative-shadow: -5px;

@keyframes shadow {
    0% {
        border: 1px solid $color-yellow;
        box-shadow: $shadow-size $shadow-size 10px $color-yellow;
    }
    25% {
        border: 1px solid $color-blue;
        box-shadow: $negative-shadow $shadow-size 10px $color-blue;
    }
    50% {
        border: 1px solid $color-red;
        box-shadow: $negative-shadow $negative-shadow 10px $color-red;
    }
    75% {
        border: 1px solid $color-green;
        box-shadow: $shadow-size $negative-shadow 10px $color-green;
    }
    100% {
        border: 1px solid $color-yellow;
        box-shadow: $shadow-size $shadow-size 10px $color-yellow;
    }
}

@media (min-width: 1000px) {
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px;
    background-color: $background-color;

    .imgs {
      display: flex;
      position: relative;
      width: 500px;
      height: 100%;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      img {
        width: 500px;
        height: 500px;
        object-fit: cover;
      }

      .blur {
        width: 500px;
        height: 500px;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(90deg, #00000000, #00000000, #00000040, #000000bf, #000000ff);
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: calc(100% - 500px);
      height: 100%;

      h2 {
        color: $primary-color;
        font-size: $font-size-48;
        margin: 0 50px 0 50px;
      }

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 450px;
        height: 50px;
        margin-top: 100px;
        animation: shadow $time infinite;
        cursor: pointer;
        border: 1px solid $color-red;
        border-radius: 25px;

        p {
          color: $primary-color;
          font-size: $font-size;

          .red {
            color: $color-red;
            font-weight: 700;
            font-size: calc($font-size + 2px);
          }
        }
      }
    }
  }
}
@media (max-width: 999px) {
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px;
    background-color: $background-color;

    .imgs {
      display: flex;
      position: relative;
      width: 350px;
      height: 100%;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      img {
        width: 100%;
        height: 300px;
        object-fit: cover;
      }

      .blur {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(90deg, #00000000, #00000000, #00000040, #000000bf, #000000ff);
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: calc(100% - 350px);
      height: 100%;

      h2 {
        color: $primary-color;
        font-size: $font-size-36;
        margin: 0 50px 0 50px;
      }

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        height: 50px;
        margin-top: 100px;
        animation: shadow $time infinite;
        cursor: pointer;
        border: 1px solid $color-red;
        border-radius: 25px;

        p {
          color: $primary-color;
          font-size: $font-size-14;
          margin: 15px;

          .red {
            color: $color-red;
            font-weight: 700;
            font-size: calc($font-size-14 + 2px);
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px;
    background-color: $background-color;

    .imgs {
      display: flex;
      position: relative;
      width: 100%;
      height: auto;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      margin-top: 50px;

      img {
        width: 100%;
        height: auto;
      }

      .blur {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(90deg, #000000ff, #00000040, #00000000, #00000000, #00000040, #000000ff);
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;

      h2 {
        color: $primary-color;
        font-size: $font-size-24;
        margin: 0 50px 0 50px;
      }

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        height: 50px;
        margin-top: 50px;
        animation: shadow $time infinite;
        cursor: pointer;
        border: 1px solid $color-red;
        border-radius: 25px;

        p {
          color: $primary-color;
          font-size: $font-size-14;
          margin: 15px;

          .red {
            color: $color-red;
            font-weight: 700;
            font-size: calc($font-size-14 + 2px);
          }
        }
      }
    }
  }
}
</style>