<template>
  <div :class="{ menuOpen: isMenuOpen, menuNoOpen: !isMenuOpen }" class="nav">
    <div class="computer">
      <div @click="toggleMenu" class="hamburger">
        <i v-if="!isMenuOpen" class="fas fa-bars"></i>
        <i v-else class="fa-solid fa-x"></i>
      </div>
      <div class="logo">
        <img
          src="../assets/Logo.svg"
          alt="Logo of Creative Pixel Studio"
          width="120px"
          height="120px"
        />
      </div>
      <div class="menu">
        <ul>
          <li @click.prevent="scrollToHome">Home</li>
          <li @click.prevent="scrollToAbout">About Us</li>
          <li @click.prevent="scrollToServices">Services</li>
          <li @click.prevent="scrollToProcess">Process</li>
          <li @click.prevent="scrollToContact">Contact Us</li>
          <li @click.prevent="scrollToFAQs">FAQs</li>
        </ul>
      </div>
    </div>
    <div
      :class="{ visible: isMenuOpen, invisible: !isMenuOpen }"
      class="menu_phone"
    >
      <ul>
        <li @click.prevent="scrollToHome">Home</li>
        <li @click.prevent="scrollToAbout">About Us</li>
        <li @click.prevent="scrollToServices">Services</li>
        <li @click.prevent="scrollToProcess">Process</li>
        <li @click.prevent="scrollToContact">Contact Us</li>
        <li @click.prevent="scrollToFAQs">FAQs</li>
      </ul>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "Nav",
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    scrollToHome() {
      const element = document.getElementById("home");
      if (element) {
        element.scrollIntoView({behavior: "smooth"});
      }
    },
    scrollToContact() {
      const element = document.getElementById("contact-us");
      if (element) {
        element.scrollIntoView({behavior: "smooth"});
      }
    },
    scrollToProcess() {
      const element = document.getElementById("process");
      if (element) {
        element.scrollIntoView({behavior: "smooth"});
      }
    },
    scrollToServices() {
      const element = document.getElementById("services");
      if (element) {
        element.scrollIntoView({behavior: "smooth"});
      }
    },
    scrollToAbout() {
      const element = document.getElementById("about");
      if (element) {
        element.scrollIntoView({behavior: "smooth"});
      }
    },
    scrollToFAQs() {
      const element = document.getElementById("FAQs");
      if (element) {
        element.scrollIntoView({behavior: "smooth"});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$background-color: #000000;
$height-max: 150px;
$font-size: 18px;
$font-weight-nav: 700;
$icon-size: 36px;
$primary_color: #ffffff;
$color-red: #ff2a0c;

@media (min-width: 769px){
    .nav {
        background-color: $background-color;
        width: 100%;
        height: $height-max;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .computer {
            display: flex;
            width: 100%;
            height: $height-max;
            justify-content: center;
            align-items: center;

            .hamburger {
                display: none;
            }

            .logo {
                width: 15%;
                height: $height-max;
                display: flex;
                justify-content: center;
                align-items: center;
                
                img {
                    width: 120px;
                    height: auto;
                }
            }

            .menu {
                width: 85%;
                height: $height-max;
                display: flex;
                justify-content: center;
                align-items: center;

                 ul {
                    width: 100%;
                    height: $height-max;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    list-style: none;

                    li {
                        margin-right: 15px;
                        font-size: $font-size;
                        color: $primary-color;
                        font-weight: $font-weight-nav;
                        cursor: pointer;
                    }

                    li:hover {
                      color: $color-red;
                    }
                 }
            }
        }

        .menu_phone {
            display: none;
        }
    }
}

@media (max-width: 768px){
    .nav {
        width: 100%;
        height: $height-max;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $background-color;
        overflow: hidden;
        transition: height ease 1s;

        .computer {
            width: 100%;
            height: $height-max;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            
            .hamburger {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20%;
                height: 100%;

                i {
                    font-size: $icon-size;
                    color: $primary-color;
                }
            }

            .logo {
                position: absolute;
                left: calc(50% - 60px);
                top: calc(50% - 60px);
            }

            .menu {
                display: none;
            }
        }

        .menu_phone {
            width: 100%;
            height: 0;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            transition: height ease 1s;
        }
    }

    .nav.menuOpen {
        height: 400px;

        .menu_phone {
            width: 100%;
            height: 300px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    list-style: none;

                    li {
                        margin-bottom: 10px;
                        font-size: $font-size;
                        color: $primary-color;
                        font-weight: $font-weight-nav;
                    }
                 }
        }
    }

    .nav.menuNoOpen {
        height: $height-max;

        .menu_phone {
            width: 100%;
            height: 0;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    list-style: none;

                    li {
                        margin-bottom: 10px;
                        font-size: $font-size;
                        color: $primary-color;
                        font-weight: $font-weight-nav;
                    }
                 }
        }
    }
}
</style>
