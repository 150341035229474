<template>
  <div class="base">
    <div class="top">
      <div class="logo">
        <img
          src="../assets/Logo.svg"
          alt="Logo on footer of creative pixel studio"
          width="120px"
          height="120px"
        />
        <span>Creative Pixel Studio</span>
      </div>
      <div class="company">
        <h2>Company</h2>
        <ul>
          <li @click.prevent="scrollToHome">
            <span>Home</span>
          </li>
          <li @click.prevent="scrollToServices">
            <span>Services</span>
          </li>
          <li @click.prevent="scrollToProcess">
            <span>Process</span>
          </li>
          <li @click.prevent="scrollToContact">
            <span> Contact </span>
          </li>
          <li @click.prevent="scrollToFAQs">
            <span>FAQs</span>
          </li>
        </ul>
      </div>
      <div class="contact">
        <h2>Contact Us</h2>
        <ul>
          <li>
            <i class="fa-solid fa-phone green"></i>
            <span>(425) 521-1233</span>
          </li>
          <li>
            <i class="fa-solid fa-envelope blue"></i>
            <span>al16760563@gmail.com</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="bottom">
      <div class="rights">
        <span>&copy; 2024 Creative Pixel Studio. All rights reserved.</span>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
    name: "base",
    methods: {
      scrollToHome() {
        const element = document.getElementById("home");
        if (element) {
          element.scrollIntoView({behavior: "smooth"});
        }
      },
      scrollToContact() {
        const element = document.getElementById("contact-us");
        if (element) {
          element.scrollIntoView({behavior: "smooth"});
        }
      },
      scrollToProcess() {
        const element = document.getElementById("process");
        if (element) {
          element.scrollIntoView({behavior: "smooth"});
        }
      },
      scrollToServices() {
        const element = document.getElementById("services");
        if (element) {
          element.scrollIntoView({behavior: "smooth"});
        }
      },
      scrollToAbout() {
        const element = document.getElementById("about");
        if (element) {
          element.scrollIntoView({behavior: "smooth"});
        }
      },
      scrollToFAQs() {
        const element = document.getElementById("FAQs");
        if (element) {
          element.scrollIntoView({behavior: "smooth"});
        }
      },
    },
}
</script>

<style lang="scss" scoped>
$primary_color: #ffffff;
$green_color: #53c216;
$blue_color: #0084ff;
$second_color: #000000;
$title_size: 24px;
$font-size: 18px;

@media (min-width: 769px){
    .base {
        width: 100%;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $second_color;

        .top {
            width: 100%;
            height: 250px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .logo {
                width: 30%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                img {
                    width: 120px;
                    height: auto;
                }

                span {
                    color: $primary_color;
                    font-size: $font-size;
                }
            }

            .company, .contact {
                 width: 35%;
                 height: 100%;
                 display: flex;
                 flex-direction: column;
                 justify-content: center;
                 align-items: center;

                 h2 {
                    color: $primary_color;
                    font-size: $title_size;
                 }

                 ul {
                    display: flex;
                    flex-direction: column;
                    list-style: none;
                    margin-top: 30px;

                    li {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        margin-bottom: 10px;
                        cursor: pointer;

                        i {
                            margin-right: 20px;
                            font-size: 18px;
                        }

                        i.blue {
                            color: $blue_color;
                        }
                        i.green {
                            color: $green_color;
                        }

                        span {
                            color: $primary_color;
                            $font-size: $font-size;
                            margin-top: 5px;
                        }
                    }
                 }
            }
        }

        .bottom {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;

            .rights {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    font-size: $font-size;
                    color: $primary_color;
                }
            }
        }
    }
}
@media (max-width: 768px){
    .base {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        background-color: $second_color;

        .top {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .logo {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: auto;
            justify-content: center;
            align-items: center;

            img {
              width: 120px;
              height: auto;
            }

            span {
              color: $primary_color;
              font-size: $font-size;
            }
          }

          .company, .contact {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: auto;
            margin-top: 20px;

            h2 {
              color: $primary_color;
              font-size: $title_size;
            }

            ul  {
              display: flex;
              flex-direction: column;
              width: 100%;
              height: auto;
              justify-content: center;
              align-items: center;
              margin-top: 20px;

              li {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 80%;
                height: auto;
                margin-top: 5px;

                i {
                  font-size: $title_size;
                  margin-right: 20px;
                }

                i.green {
                  color: $green_color;
                }

                i.blue {
                  color: $blue_color;
                }

                span {
                  font-size: $font-size;
                  color: $primary_color;
                }
              }
            }
          }
        }

        .bottom {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;

          .rights {
            width: 100%;
            height: auto;
            justify-content: center;
            align-items: center;
            display: flex;

            span {
              color: $primary_color;
              font-size: $font-size;
            }
          }
        }
    }
}
</style>