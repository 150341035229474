<template>
  <div class="our" id="services">
    <div class="title">
      <h2>Our Services: Crafting Digital Brilliance, One Pixel at a Time</h2>
    </div>
    <div class="container">
      <div class="red">
        <div class="subtitle">
          <h3>SEO</h3>
          <h4>(Search Engine Optimization)</h4>
        </div>
        <div class="body">
          <p>
            Boost your website’s visibility and drive more traffic with our
            expert SEO strategies, designed to get you noticed by the right
            audience.
          </p>
        </div>
      </div>
      <div class="green">
        <div class="subtitle">
          <h3>Digital Marketing</h3>
        </div>
        <div class="body">
          <p>
            Reach your customers where they are with targeted digital marketing
            campaigns that grow your brand and increase engagement across
            platforms.
          </p>
        </div>
      </div>
      <div class="blue">
        <div class="subtitle">
          <h3>Web Design</h3>
        </div>
        <div class="body">
          <p>
            We craft visually stunning, user-friendly websites that reflect your
            brand’s identity and deliver a seamless experience for your
            audience.
          </p>
        </div>
      </div>
      <div class="yellow">
        <div class="subtitle">
          <h3>Logo Design</h3>
        </div>
        <div class="body">
          <p>
            Your logo is the face of your brand. Let us create a unique,
            memorable design that communicates your values and makes a lasting
            impression.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
    name: "ourServices",
}
</script>

<style lang="scss" scoped>
$background-color: #000000;
$primary-color: #ffffff;
$font-size: 18px;
$color-red: #ff2a0c;
$color-green: #53c216;
$color-blue: #0084ff;
$color-yellow: #efe12a;

@media (min-width: 769px){
    .our {
        background-color: $background-color;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 50px;
        padding-bottom: 50px;

        .title {
            width: 100%;
            height: auto;

            h2 {
                color: $primary-color;
                margin: 20px;
            }
        }

        .container {
            width: 100%;
            height: auto;
            justify-content: space-around;
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            gap: 25px;

            div {
                width: 350px;
                height: 250px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .subtitle {
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    h3 {
                        color: $primary-color;
                        margin-top: 50px;
                    }

                    h4 {
                        color: $primary-color;
                    }
                }

                .body {
                    margin: 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    p {
                        color: $primary-color;
                        font-size: $font-size;
                        margin: 15px;
                    }
                }
            }

            div.red {
                border-radius: 15px;
                border: 1px solid $color-red;
                box-shadow: -5px -5px $color-red;
            }
            
            div.green {
                border-radius: 15px;
                border: 1px solid $color-green;
                box-shadow: -5px -5px $color-green;
            }

            div.blue {
                border-radius: 15px;
                border: 1px solid $color-blue;
                box-shadow: -5px -5px $color-blue;
            }

            div.yellow {
                border-radius: 15px;
                border: 1px solid $color-yellow;
                box-shadow: -5px -5px $color-yellow;
            }
        }
    }
}
@media (max-width: 768px){
  .our {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    background-color: $background-color;
    padding: 50px 0 50px 0;

    .title {
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;

      h2 {
        color: $primary-color;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      justify-content: center;
      align-items: center;

      div {
        width: 300px;
        height: auto;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 15px;

        .subtitle {
          width: 90%;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          h3, h4 {
            color: $primary-color;
          }
        }

        .body {
          width: 90%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 20px;

          p {
             color: $primary-color;
             font-size: $font-size;
          }
        }
      }

      div.red {
                border: 1px solid $color-red;
                box-shadow: -5px -5px $color-red;
            }
            
            div.green {
                border: 1px solid $color-green;
                box-shadow: -5px -5px $color-green;
            }

            div.blue {
                border: 1px solid $color-blue;
                box-shadow: -5px -5px $color-blue;
            }

            div.yellow {
                border: 1px solid $color-yellow;
                box-shadow: -5px -5px $color-yellow;
            }
    }
  }
}
</style>