<template>
  <div class="home">
    <navegador></navegador>
    <TitleCTA></TitleCTA>
    <WhoWeAre></WhoWeAre>
    <OurServices></OurServices>
    <Process></Process>
    <Contact></Contact>
    <FAQs></FAQs>
    <Base></Base>
  </div>
</template>

<script>
// @ is an alias to /src
import navegador from "@/components/Nav.vue";
import Base from "@/components/Base.vue";
import WhoWeAre from "@/components/WhoWeAre.vue";
import OurServices from "@/components/OurServices.vue";
import Process from "@/components/Process.vue";
import FAQs from "@/components/FAQs.vue";
import TitleCTA from "@/components/TitleCTA.vue";
import Contact from "@/components/Contact.vue";

export default {
  name: "HomeView",
  components: {
    navegador,
    TitleCTA,
    Base,
    WhoWeAre,
    OurServices,
    Process,
    Contact,
    FAQs,
  },
  metaInfo: {
    title:
      "Creative Pixel Studio | Diseño Web y Gráfico en Everett, Washington",
    meta: [
      // Descripción principal para SEO en español
      {
        name: "description",
        content:
          "Creative Pixel Studio ofrece soluciones innovadoras en diseño web y gráfico para ayudar a negocios en Everett, Washington, a destacar en el mundo digital.",
      },
      // Descripción principal para SEO en inglés
      {
        name: "description:en",
        content:
          "Creative Pixel Studio provides innovative web and graphic design solutions to help businesses in Everett, Washington, stand out in the digital world.",
      },
      // Palabras clave para SEO en español
      {
        name: "keywords",
        content:
          "Creative Pixel Studio, diseño web, diseño gráfico, Everett, Washington, branding, desarrollo web",
      },
      // Palabras clave para SEO en inglés
      {
        name: "keywords:en",
        content:
          "Creative Pixel Studio, web design, graphic design, Everett, Washington, branding, web development",
      },
      // Meta para redes sociales (Open Graph para Facebook, LinkedIn) en español
      {
        property: "og:title",
        content: "Creative Pixel Studio | Diseño Web y Gráfico",
      },
      {
        property: "og:description",
        content:
          "Agencia de diseño web y gráfico en Everett, Washington. Nuestro equipo crea experiencias digitales únicas para impulsar tu marca.",
      },
      // Open Graph en inglés
      {
        property: "og:title:en",
        content: "Creative Pixel Studio | Web and Graphic Design",
      },
      {
        property: "og:description:en",
        content:
          "Web and graphic design agency in Everett, Washington. Our team creates unique digital experiences to boost your brand.",
      },
      {
        property: "og:image",
        content: "https://www.creative-pixelstudio.com/Logo-black.svg", // Enlace a una imagen de vista previa para redes sociales
      },
      {
        property: "og:url",
        content: "https://www.creative-pixelstudio.com",
      },
      // Meta de viewport para diseño responsivo
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1",
      },
      // Autores y derechos
      {
        name: "author",
        content: "Creative Pixel Studio",
      },
      {
        name: "copyright",
        content: "Creative Pixel Studio © 2024",
      },
    ],
    link: [
      // Enlace a un favicon
      {
        rel: "icon",
        href: "/favicon.ico",
      },
    ],
  },
};
</script>
